import { ApiClientProps } from "hooks/useApi";

export interface MenuGroup {
  menuGroupId: string;
  menuGroupPosId: string | null;
  name: string;
  tableStatus: number;
}

export interface GetTableStatusPropsResponse {
  locationId: string | null;
  partnerId: number | null;
  lastUpdate: string | null;
  kdsEnabled: boolean | undefined;
  tableStatusMap: { [key: string]: MenuGroup };
}

interface GetLocationsProps {
  id?: number | undefined;
  locationId: string | undefined;
  partnerId: string | undefined;
  posType?: string | undefined;
  token: string | undefined;
  apiClient: ApiClientProps;
}

async function getTableStatus({
  id,
  locationId,
  partnerId,
  posType,
  token,
  apiClient,
}: GetLocationsProps): Promise<GetTableStatusPropsResponse | undefined> {
  if (!locationId || !id || !posType) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }
  
  if (!token) {
    throw new Error("No token provided for fetching TableStatus");
  }

  const urlLocationId = posType === "upserve" ? id : locationId;

  const path = `/middleware-admin/partners/${partnerId}/pos/${posType}/status/${urlLocationId}`;

  try {
    const response = await apiClient.get({
      path,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      noRetry: true,
    });

    return response.resource;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default getTableStatus;
