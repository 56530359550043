import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import getTableStatus, {
  GetTableStatusPropsResponse as TableStatusDataProps,
} from "utilities/getTableStatus";
import updateTableStatus, {
  TableStatusMenuGroupMapProps,
} from "utilities/updateTableStatus";

import { addBreadcrumb } from "@sentry/react";
import isDifferent from "utilities/isDifferent";
import { queryKeys } from "utilities/constants";
import updateTableStatusEnabled from "utilities/updateTableStatusEnabled";
import useApi from "hooks/useApi";
import { useAuth } from "./AuthContext";
import { useLocation } from "./LocationContext";
import { useNotifications } from "./NotificationsContext";

interface TableStatusContextProps {
  tableStatusData?: TableStatusDataProps;
  isTableStatusError: boolean;
  isTableStatusLoading: boolean;
  tableStatusError: Error | unknown | null;
  isKdsEnabled: boolean | undefined;
  isKdsEnabledInitialState: boolean | undefined;
  handleIsKdsEnabled: (isKdsEnabled: boolean) => void;
  refetchTableStatus?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<TableStatusDataProps | undefined, unknown>>;
  handleUpdateTableStatusEnabled: (checked: boolean) => void;
  updateMappingState: (menuGroupId: string, tableStatus: number) => void;
  cancelChanges: () => void;
  isTableStatusMappingsSaving: boolean;
  // isTableStatusSaving: boolean;
  isTableStatusEnabled: boolean;
  initialMappingState: TableStatusMenuGroupMapProps | null | undefined;
  mappingState: TableStatusMenuGroupMapProps | null | undefined;
  // setIsCheckMatchEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  /* setMappingState: React.Dispatch<
    React.SetStateAction<TableStatusMenuGroupMapProps | null>
  >; */
  // updateCheckMatch: (newCheckMatchData: TableStatusDataProps) => void;
  updateTableStatusMappings: () => void;
}

export const TableStatusContext = createContext<TableStatusContextProps | null>(
  null
);

TableStatusContext.displayName = "TableStatusContext";

export function TableStatusProvider(props: any): React.ReactElement {
  const [tableStatusData, setTableStatusData] =
    useState<TableStatusContextProps["tableStatusData"]>();
  const [initialMappingState, setInitialMappingState] =
    useState<TableStatusMenuGroupMapProps | null>({});
  const [mappingState, setMappingState] =
    useState<TableStatusMenuGroupMapProps | null>({});
  const [isTableStatusEnabled, setIsTableStatusEnabled] =
    useState<boolean>(false);
  const [isTableStatusMappingsSaving, setIsTableStatusMappingsSaving] =
    useState<boolean>(false);
  const [isKdsEnabled, setIsKdsEnabled] =
    useState(false);
    const [isKdsEnabledInitialState, setIsKdsEnabledInitialState] =
    useState(false);

  const [isFetchError, setIsFetchError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { token } = useAuth();
  const { currentLocation, locationDetails, partnerId } = useLocation();

  const { notify } = useNotifications();

  const apiClient = useApi();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (locationDetails) {
      setIsTableStatusEnabled(locationDetails.tableStatusEnabled);
    }
  }, [locationDetails]);

  const {
    data,
    error: tableStatusError,
    isError: isTableStatusError,
    isFetching: isTableStatusLoading,
    refetch: refetchTableStatus,
  } = useQuery<TableStatusDataProps | undefined>(
    queryKeys.tableStatus,
    async () =>
      await getTableStatus({
        id: currentLocation?.id,
        locationId: currentLocation?.locationId,
        partnerId,
        posType: currentLocation?.posType,
        token,
        apiClient,
      }),
    {
      enabled:
        !!currentLocation?.id &&
        !!currentLocation?.locationId &&
        !!partnerId &&
        !!currentLocation?.posType,
      onError: (error: any) => {
        if (error?.message) {
          setErrorMessage(`Error on Table Status: ${error.message}`);
          setIsFetchError(true);
        }
      },
    }
  );

  // reset query data and mapping state on location change
  useEffect(() => {
    if (currentLocation?.id) {
      queryClient.setQueryData(queryKeys.tableStatus, undefined);
      setInitialMappingState({});
      setMappingState({});
      setTableStatusData(undefined);

      void refetchTableStatus();
    }
  }, [currentLocation?.id, queryClient, refetchTableStatus]);

  useEffect(() => {
    if (data) {
      setTableStatusData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isFetchError && errorMessage) {
      const notification = () => {
        notify({
          type: "error",
          message: errorMessage,
        });
      };

      void notification();

      setIsFetchError(false);
    }
  }, [errorMessage, isFetchError, notify]);

  // initial mapping state
  useEffect(() => {
    if (data?.tableStatusMap) {
      const initialState = Object.keys(
        data.tableStatusMap
      ).reduce<TableStatusMenuGroupMapProps>((acc, key) => {
        const item = data.tableStatusMap[key];
        const { menuGroupId, tableStatus } = item;

        if (tableStatus !== 0) {
          acc[menuGroupId] = {
            menuGroupId,
            tableStatus,
          };
        }

        return acc;
      }, {});

      setInitialMappingState(initialState);
      setMappingState(initialState);
    }
  }, [data?.tableStatusMap]);

  useEffect(() => {
    if (data?.kdsEnabled !== undefined) {
      setIsKdsEnabled(data?.kdsEnabled);
      setIsKdsEnabledInitialState(data?.kdsEnabled);
    }
  }, [data?.kdsEnabled]);

  const updateTableStatusEnabledMutation = useMutation(
    async (tableStatusEnabled: boolean) => {
      if (!locationDetails) {
        console.error(
          "updateTableStatusEnabledMutation: Location Details are missing."
        );

        return;
      }

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update tableStatusEnabled for locationId:${currentLocation?.id}`,
      });

      const response = await updateTableStatusEnabled({
        tableStatusEnabled,
        locationDetails,
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: () => {
        // reset tableStatus data for optimistic UI update
        queryClient.setQueryData(queryKeys.tableStatus, undefined);

        queryClient.setQueryData(queryKeys.locationDetails, {
          ...locationDetails,
          tableStatusEnabled: !isTableStatusEnabled,
        });

        notify({
          type: "success",
          message: "Location Config updated successfully!",
        });
      },
      onError: (error: any) => {
        notify({
          type: "error",
          message: `Error enabling TableStatus: ${error?.message}`,
        });

        console.error("There was an error enabling TableStatus:", error);
      },
    }
  );

  const handleUpdateTableStatusEnabled = useCallback(
    (checked: boolean) => {
      setIsTableStatusEnabled(checked);
      updateTableStatusEnabledMutation.mutate(checked);
    },
    [updateTableStatusEnabledMutation]
  );

  const handleIsKdsEnabled = useCallback(
    (isKdsEnabled: boolean) => {
      setIsKdsEnabled(isKdsEnabled);
    },
    [isKdsEnabled]
  );
  
  
  const updateMappingState = useCallback(
    (menuGroupId: string, tableStatus: number) => {
      setMappingState((prevState) => {
        if (!prevState || !menuGroupId) return prevState;

        return {
          ...prevState,
          [menuGroupId]: { menuGroupId, tableStatus },
        };
      });
    },
    []
  );

  const cancelChanges = useCallback(() => {
    setMappingState(initialMappingState);
    setIsKdsEnabled(isKdsEnabledInitialState);
  }, [initialMappingState]);

  const updateTableStatusMappingsMutation = useMutation(
    async (newMapping: TableStatusMenuGroupMapProps) => {
      if (!tableStatusData || !locationDetails) {
        return;
      }

      setIsTableStatusMappingsSaving(true);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Update TableStatusMappings for locationId:${currentLocation?.id}`,
      });

      const response = await updateTableStatus({
        tableStatusData: {
          ...tableStatusData,
          locationId: locationDetails.locationId,
          kdsEnabled: isKdsEnabled,
        },
        newMapping,
        partnerId,
        token,
        apiClient,
      });

      return response;
    },
    {
      onSuccess: async () => {
        notify({ type: "success", message: "Data saved successfully!" });
        setIsTableStatusMappingsSaving(false);

        await refetchTableStatus();
      },
      onError: (error: any) => {
        setIsTableStatusMappingsSaving(false);

        notify({
          type: "error",
          message: `Error saving TableStatus: ${error?.message}`,
        });

        console.error("There was an error saving the data: ", error);
      },
    }
  );

  const handleUpdateTableStatusMappings = useCallback(() => {
    if (!mappingState) {
      return;
    }

    // remove all "no maps"
    const cleanedUpMap = Object.entries(
      mappingState
    ).reduce<TableStatusMenuGroupMapProps>(
      (newState, [key, { tableStatus }]) => {
        if (tableStatus !== 0) {
          newState[key] = {
            menuGroupId: key,
            tableStatus,
          }; // Keep the entry if the tableStatus is not 'no map'
        }
        // Otherwise, it's omitted from the newState
        return newState;
      },
      {}
    );

    const isMapDifferent =
      initialMappingState &&
      cleanedUpMap &&
      isDifferent(initialMappingState, cleanedUpMap);

    const hasKdsEnabledChange = isKdsEnabledInitialState !== isKdsEnabled;
    
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (isMapDifferent || hasKdsEnabledChange) {
      updateTableStatusMappingsMutation.mutate(cleanedUpMap);
    } else {
      cancelChanges();
      // send a notification?
    }
  }, [
    cancelChanges,
    initialMappingState,
    mappingState,
    updateTableStatusMappingsMutation,
  ]);

  const value: TableStatusContextProps = {
    tableStatusData,
    cancelChanges,
    handleUpdateTableStatusEnabled,
    initialMappingState,
    mappingState,
    isTableStatusEnabled,
    isTableStatusError,
    isTableStatusLoading,
    isTableStatusMappingsSaving,
    tableStatusError,
    isKdsEnabled,
    isKdsEnabledInitialState,
    handleIsKdsEnabled,
    refetchTableStatus,
    updateMappingState,
    updateTableStatusMappings: handleUpdateTableStatusMappings,
  };

  return (
    <TableStatusContext.Provider value={value} {...props} />
  ) as React.ReactElement;
}

export function useTableStatus(): TableStatusContextProps {
  const context = useContext(TableStatusContext);

  if (context === undefined) {
    throw new Error("useTableStatus must be used within a TableStatusProvider");
  }

  // Because we are getting the context from TableStatusProvider here, if context is
  // not 'undefined' (ie: the TableStatusProvider is not a parent of this component),
  // we can be sure context is not 'null' here
  if (context === null) {
    throw new Error("TableStatusProvider supplied null context");
  }

  return context;
}
