import * as Styled from "./TableStatus.styles";

import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";

import { Button, Switch } from "antd";
import { MenuGroupProps } from "utilities/getLocationDetails";
import TableStatusMappingButtons from "components/TableStatusMappingButtons/TableStatusMappingButtons";
import isDifferent from "utilities/isDifferent";
import { useLocation } from "context/LocationContext";
import { useParams } from "react-router-dom";
import { useTableStatus } from "context/TableStatusContext";

function TableStatus() {
  const [save, setSave] = useState(false);
  const { posGUID } = useParams();

  const { locationDetails } = useLocation();

  const {
    tableStatusData,
    initialMappingState,
    isTableStatusMappingsSaving,
    isKdsEnabled,
    isKdsEnabledInitialState,
    handleIsKdsEnabled,
    mappingState,
    cancelChanges,
    updateMappingState,
    updateTableStatusMappings,
  } = useTableStatus();

  const tableStatusMap = tableStatusData?.tableStatusMap ?? {};

  const tableStatusMapArray = Object.keys(tableStatusMap).map((key) => {
    const item = tableStatusMap[key];

    return {
      name: item.name,
      key,
      item,
    };
  });

  // reset state on location changed
  useEffect(() => {
    if (posGUID) {
      setSave(false);
    }
  }, [posGUID]);

  useEffect(() => {
    const isMapDifferent =
      initialMappingState &&
      mappingState &&
      isDifferent(initialMappingState, mappingState);

    if (isMapDifferent) {
      setSave(true);
    } else {
      setSave(false);
    }
  }, [initialMappingState, mappingState]);

  useEffect(() => {
    if (isKdsEnabledInitialState !== isKdsEnabled) {
      setSave(true);
    } else {
      setSave(false);
    }
  }, [isKdsEnabledInitialState, isKdsEnabled]);

  const handleMapping = (menuGroupId: string, tableStatus: number) => {
    updateMappingState(menuGroupId, tableStatus);
  };

  const handleSave = useCallback(() => {
    updateTableStatusMappings();
  }, [updateTableStatusMappings]);

  const handleCancel = useCallback(() => {
    cancelChanges();
    setSave(false);
  }, [cancelChanges]);

  const handleChangeEnabled = useCallback(() => {
    handleIsKdsEnabled(!isKdsEnabled);
    setSave(true);
  }, [handleIsKdsEnabled, isKdsEnabled]);

  const columns = [
    {
      title: "Menu Group",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Mapping",
      key: "item",
      dataIndex: "item",
      render: (item: MenuGroupProps) => {
        return (
          <TableStatusMappingButtons
            item={item}
            mappingState={mappingState}
            onHandleMapping={handleMapping}
          />
        );
      },
    },
  ];

  return (
    <>
      <Styled.ButtonsRow>
        <Styled.SwitchContainer>
          KDS Enabled
          <Switch
            key={locationDetails?.locationId}
            checked={isKdsEnabled}
            onChange={handleChangeEnabled}
            title="KDS Enabled"
          />
        </Styled.SwitchContainer>
        <Button
          disabled={!save || isTableStatusMappingsSaving}
          loading={isTableStatusMappingsSaving}
          onClick={handleSave}
          type="primary"
          icon={<SaveOutlined />}
        >
          Save Changes
        </Button>
        <Button
          disabled={!save || isTableStatusMappingsSaving}
          onClick={handleCancel}
          type="default"
          icon={<CloseOutlined />}
        >
          Cancel
        </Button>
      </Styled.ButtonsRow>
      <Styled.Table
        key={locationDetails?.locationId}
        columns={columns}
        dataSource={tableStatusMapArray}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
}

export default TableStatus;
